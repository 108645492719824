import React, { FC } from 'react';
import { Link } from 'gatsby';

import Image from 'common/Image';

import { ProductListProps } from './models';

import './productListComponent.scss';

const ProductListComponent: FC<ProductListProps> = ({ product, purchaseLinkText }) => {
  const { basicInfo, url } = product;
  const { name, image } = basicInfo[0];
  const { activeIngredient } = basicInfo[0].activeIngredient[0];
  const flavorRender = basicInfo[0].flavor ? (
    <p className="product__flavor">
      flavor
      {basicInfo[0].flavor}
    </p>
  ) : null;

  return (
    <>
      <div className="product__image">
        <Image imageData={image} alt={name} />
      </div>
      <div className="product__title">
        <h1 className="title">{name}</h1>
      </div>
      <div className="product__body">
        <p className="product__ingredient">{activeIngredient}</p>
        {basicInfo[0].concentration ? (
          <p className="product__concentration">{basicInfo[0].concentration}</p>
        ) : null}
        {basicInfo[0].age ? <p className="product__age">{basicInfo[0].age}</p> : null}
        {flavorRender}
        <div className="product__btn">
          <Link to={url} className="btn__link btn">
            {purchaseLinkText}
          </Link>
        </div>
      </div>
    </>
  );
};

export default ProductListComponent;
